import React, { useEffect, useCallback } from 'react';
import { ZoomMtg } from '@zoomus/websdk';
import { useParams } from 'react-router-dom';
import axios from 'supports/api';

const MentoringPage = () => {
  const { encryptedSessionId } = useParams();

  const initializeZoom = useCallback(async () => {
    try {
      const axiosRes = await axios.get(
        '/v2/program-mentoring-session/generate-signature',
        {
          params: { mentoringId: encryptedSessionId },
        },
      );

      const {
        signature,
        meetingId,
        meetingPassword,
        attendeeName,
        attendeeEmail,
      } = axiosRes?.data?.data;

      ZoomMtg.setZoomJSLib('https://source.zoom.us/2.12.0/lib', '/av');
      ZoomMtg.preLoadWasm();
      ZoomMtg.prepareWebSDK();

      ZoomMtg.init({
        leaveUrl: `${process.env.REACT_APP_REDIRECT_URL}/dashboard/program`,
        isSupportAV: true,
        success: (res) => {
          console.log(res);
          ZoomMtg.join({
            signature,
            meetingNumber: meetingId,
            userName: attendeeName,
            sdkKey: process.env.REACT_APP_ZOOM_SDKKEY,
            userEmail: attendeeEmail,
            passWord: meetingPassword,
            success: (success) => {
              console.log(success);
            },
            error: (error) => {
              console.log(error);
            },
          });
        },
        error: (error) => {
          console.log(error);
        },
      });
    } catch (err) {
      alert('Connection cannot be established');
      console.log(err);
      window.location.href = `${process.env.REACT_APP_REDIRECT_URL}/dashboard/program`;
    }
  }, [encryptedSessionId]);

  useEffect(() => {
    initializeZoom();
  }, [initializeZoom]);

  return <div id="mentoring" />;
};

export default MentoringPage;
