import React from 'react';
import { Route, Switch } from 'react-router-dom';
import ProgramLivestreamPage from 'pages/program-livestream';
import MentoringPage from 'pages/mentoring';
import PracticeSessionPage from 'pages/practice-session';
import './App.css';

const App = () => {
  return (
    <div className="App">
      <Switch>
        <Route
          exact
          path="/lv/:encryptedLivestreamPayload"
          component={ProgramLivestreamPage}
        />
        <Route
          exact
          path="/ps/:encryptedPracticeSessionSchedulePayload"
          component={PracticeSessionPage}
        />
        <Route
          exact
          path={['/', '/:encryptedSessionId']}
          component={MentoringPage}
        />
      </Switch>
    </div>
  );
};

export default App;
